:root {
  --color-primary-light: #4b515a;
  --color-primary-main: #1E2631;
  --color-primary-dark: #151a22;
  --color-secondary-main: #0BD1C2;
  --color-secondary-dark: #05635c;
  --color-highlight-one: #9EF62E;
  --color-highlight-two: #FF70A6;
}

.main-wrapper {
  margin-top: 55px;
  min-height: 100vh;
}

.container {
  padding: 20px;
  padding-top: 10px;
}

.sandbox-notification {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  background-color: var(--color-highlight-one);
  margin-bottom: -20px;
  z-index: 1;
  opacity: 0.7;
}

.impersonation-notification {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  background-color: var(--color-highlight-two);
  margin-top: 20px;
  margin-bottom: -20px;
  z-index: 1;
  opacity: 0.7;
}

.hidden {
  display: none;
}

.hide-mobile {
  display: none;
}

.team-card h3 {
  margin: 0;
  font-size: 24px;
}

.team-card .image-wrapper img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

a {
  color: var(--color-secondary-dark);
  text-decoration: underline;
}

a:hover {
  color: var(--color-primary-main);
}

a:visited:not(.MuiButton-root) {
  color: var(--color-secondary-dark);
}

/* The menu */
span.MuiListItemText-primary {
  color: rgba(0, 0, 0, 0.87);
}

/* Online Course menu */
.chapter-menu-item span.MuiListItemText-primary {
  color: #ffffff;
}

.lesson-dialog {
  min-height: 400px;
}

.firebaseui-idp-password,
.mdl-button.firebaseui-idp-password:active,
.mdl-button.firebaseui-idp-password:hover {
  background-color: var(--color-primary-light);
}

.react-add-to-calendar__dropdown ul {
  margin-left: -40px !important;
}

.react-add-to-calendar__dropdown {
  z-index: 1 !important;
}

.fc-event-container:hover {
  cursor: pointer;
}

.fc-button-primary {
  background-color: var(--color-primary-light) !important;
  border: none !important;
}

.fc-button-active {
  background-color: var(--color-primary-light) !important;
}

.fc-media-screen {
  min-height: 500px !important;
}

#class-cohort-calendar .fc-event-title {
  padding: 0 1px;
  white-space: normal;
  font-size: 0.8rem;
}

#class-cohort-calendar .fc-event-time {
  display: none;
}

.fc-toolbar {
  flex-direction: column;
}

.fc-toolbar-title {
  font-size: 1.25em !important;
}

/* Center the PDF viewer */
.react-pdf__Page__canvas {
  margin: 0 auto;
}

.map-marker-pin {
  /* https://codepen.io/andreasstorm/pen/ClguF */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--color-primary-main);
  position: absolute;
  /* transform: rotate(-45deg); */
  left: 50%;
  top: 50%;
}

.scrolling-date {
  text-align: center;
  padding: 8px 0;
}

.scrolling-date:last-child {
  border-right: none;
}

.scrolling-date-header {
  min-width: 80px;
  margin: 10px;
  padding: 5px;
  padding-top: 0;
  margin-top: 0;
}

.location-circle-image {
  width: 160px;
  height: 160px;
  background-size: cover;
  display: block;
  border-radius: 100px;
  text-align: center;
  line-height: 160px;
  margin: 0 auto;
}

.location-circle-image a {
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
}

.autocomplete-dropdown-container {
  z-index: 2;
  border: 1px solid #ddd;
  border-top: none;
  margin-top: -10px;
}

.suggestion-item {
  padding: 10px;
  color: #484848;
  cursor: pointer;
  background-color: #ffffff;
}

.suggestion-item.active {
  background-color: #fafafa;
}

.page {
  margin: 0 auto;
}

.page,
.article-list {
  max-width: 800px;
  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 40px;
}

.article-list {
  margin: 0 auto;
}

.category-preview {
  max-width: none !important;
  padding-bottom: 20px !important;
  background-color: #ffffff;
}

.category-preview .article-list h2 {
  font-size: 24px;
}

.category-preview .article-list h3 {
  font-size: 16px;
}

.page .entry-header h1 {
  margin-bottom: 0;
  color: var(--color-primary-main);
}

.page .entry-header {
  margin-bottom: 20px;
  text-align: center;
}

.page .entry-header h1 {
  color: #333;
  font-size: 33px;
  font-weight: 400;
}

.page .entry-header .page-meta {
  font-size: 12px;
  color: #555;
  margin-top: 14px;
}

.article-preview .preview-meta {
  font-size: 12px;
  color: #555;
}

.page img {
  max-width: 100%;
}

.page .entry-body {
  line-height: 1.7;
  letter-spacing: -0.004em;
  font-size: 18px;
  margin-bottom: 40px;
}

.article-list h2 {
  font-weight: 400;
}

.article-preview h3 {
  margin-bottom: 0;
  font-weight: 400;
}

.article-preview a {
  color: var(--color-primary-main);
}

.preview-meta a {
  color: var(--color-primary-main);
}

/* Animated elipses */
.animated-elipses:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.user-log-table th,
.user-log-table td {
  padding-left: 0;
  padding-right: 15px;
}

.icon-text-block .icon {
  margin-right: 10px;
}

.icon-text-block h3 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 18px;
  font-weight: 700 !important;
}

.icon-text-block .icon svg {
  font-size: 24px;
}

#landing-page-video-hero .video-content-inner {
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  padding: 40px;
}

.landing-page.hero-section .lead-block p {
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.video-responsive {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pickup-location-bar {
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgb(245, 245, 245);
  position: sticky;
  margin-top: -2px;
  z-index: 1199;
  top: 64px;
}

.car-animation {
  animation: shake 2s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(0px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(0px, 0px) rotate(-1deg);
  }

  20% {
    transform: translate(0px, -1px) rotate(0deg);
  }

  30% {
    transform: translate(0px, 0px) rotate(1deg);
  }

  40% {
    transform: translate(0px, 1px) rotate(0deg);
  }

  50% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  60% {
    transform: translate(0px, 1px) rotate(-1deg);
  }

  70% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  80% {
    transform: translate(0px, -1px) rotate(0deg);
  }

  90% {
    transform: translate(0px, 1px) rotate(0deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(1deg);
  }
}

.date-underline {
  border-bottom: 2px solid var(--color-secondary-main);
  display: inline-block;
  line-height: 1;
}

.thick-underline {
  border-bottom: 4px solid var(--color-secondary-main);
  display: inline-block;
  line-height: 0.85;
}

.review-v3 {
  height: 100%;
  font-size: 16px;
  padding: 20px 40px !important;
  line-height: 1.45;
  position: relative;
  color: #383838;
}

.review-v3-has-text:before {
  display: block;
  padding-left: 10px;
  content: '\201C';
  font-size: 80px;
  position: absolute;
  left: -5px;
  top: -20px;
  color: var(--color-primary-main);
}

.review-v3 cite {
  color: #999999;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

.syllabus-button svg {
  color: var(--color-primary-main);
}

.syllabus-button span {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 300;
}

.floating-box {
  background-color: #fafafa;
  border-radius: 5px;
  padding: 25px;
  margin: 0 auto;
  box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
}

.top-accent {
  border-top: 4px var(--color-secondary-main) solid;
}

.de-sidebar {
  max-width: 360px;
}

.button-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-primary-light);
  text-align: left;
  font-family: Poppins, sans-serif;
}

.button-as-link:hover {
  text-decoration: underline;
}

.course-content {
  max-width: 600px;
}

.course-content img {
  max-width: 100%;
}

.course-navigation-footer {
  max-width: 600px;
  background: #f8f8f8;
  display: flex;
  padding: 20px;
  align-items: center;
  margin-top: 20px;
}

.course-report-problem-container {
  max-width: 600px;
  margin-top: 20px;
}

.course-progress-wrapper {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  text-align: center;
}

.course-progress-wrapper .volume-bar {
  background-color: #ffffff !important;
}

.progress-text {
  color: #ffffff;
}

.chapter-menu-item {
  text-transform: uppercase;
  color: #ffffff;
}

.chapter-menu-item .MuiListItemText-primary {
  font-size: 12px;
  color: #ffffff;
}

.lecture-menu-item .MuiListItemText-primary {
  font-size: 14px;
  text-transform: none;
  color: #ffffff;
}

.chapter-menu-icon {
  color: #ffffff !important;
}

.recent-viewed {
  padding: 10px;
  font-size: 0.8rem;
}

.recent-viewed ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

.cal-max-lessons-floater {
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: rgb(225, 225, 225);
  color: #333;
  width: 80px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
}

.cal-max-highlight {
  background-color: var(--color-secondary-main);
  color: #ffffff;
}

.waitlist-details-row td {
  border-bottom: none;
}

.waitlist-comments-row tr {
  border-bottom: none;
}

.date-time-group-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.lesson-card-student .MuiListItemIcon-root {
  min-width: 36px;
}

.lesson-card-student .MuiListItem-gutters {
  padding-left: 0;
}

.footer {
  background-color: var(--color-primary-main);
  color: #ffffff;
  display: block;
  font-size: 0.95rem;
  padding: 20px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.footer a {
  color: #ffffff;
}

.footer a:visited {
  color: #ffffff !important;
}

.footer li {
  list-style: none;
}

.footer ul {
  padding: 0;
  margin: 0;
}

.sub-tree li {
  margin-left: 4px;
}

.metrics-grid.red {
  background-color: rgba(244, 67, 54, 0.1);
  color: rgb(244, 67, 54);
  font-weight: 600;
}

.metrics-grid.green {
  background-color: rgba(76, 175, 80, 0.1);
  color: rgb(76, 175, 80);
  font-weight: 600;
}

.metrics-grid-cell-selected {
  background-color: var(--color-primary-main) !important;
  color: #ffffff !important;
  font-weight: 600;
}

.mini-package-block:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.picker-block {
  padding: 20px;
}

.picker-block.enabled {
  cursor: pointer;
}

.picker-block.disabled {
  cursor: default;
  background: #e3e3e3;
  color: #888;
}

.picker-block.selected {
  border: 1px solid var(--color-secondary-main);
}

.picker-block.enabled:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.price-strike {
  position: relative;
  display: inline-block;
}

.price-strike::before {
  content: '';
  border-bottom: 2px solid red;
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: rotate(-20deg);
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #888;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.package-discount-pill {
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  height: 47px;
}

/* Remove a thin grey line from above the accordion */
.MuiAccordion-root:before {
  background-color: #ffffff !important;
}

.account-alerts .MuiAlert-root {
  margin-bottom: 10px;
}

.marketing-section {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 20px;
  padding-bottom: 40px;
}

.button-cta {
  border-style: solid;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  padding: 20px;
}

.marketing-section img {
  max-width: 100%;
}

.marketing-section .marketing-section-head {
  text-align: center;
  margin-bottom: 40px;
}

.package-section-container {
  padding: 0;
}

.package-section-head {
  background-color: var(--color-highlight-two);
  padding: 20px;
  margin-bottom: 0 !important;
}

.lead-block h1 {
  font-size: 44px;
  line-height: 44px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0px;
}

.marketing-section h2 {
  font-size: 34px;
  line-height: 34px;
  font-weight: 400;
}

.lead-block h2 {
  font-size: 24px;
}

.marketing-section h3 {
  font-weight: 500;
}

.landing-page .image-caption {
  text-align: right;
  margin-top: 0;
  font-size: 12px;
}

.header-image-block {
  background-size: cover;
  min-height: 350px;
  position: relative;
  background-position: top;
}

/* Makes date numbers appear over the events. */
.rbc-date-cell {
  z-index: 1;
}

.hero-section {
  color: var(--color-primary-main);
  padding: 0;
}

.hero-section .city-autocomplete-wrapper {
  display: block !important;
}

.hero-section .city-autocomplete-wrapper .button-wrapper {
  margin-left: 0 !important;
}

.hero-section .city-autocomplete-wrapper .button-wrapper button {
  width: 100%;
  margin-top: 10px;
}

.de-mega-hero {
  padding-left: 20px;
  padding-right: 20px;
}

.landing-page.hero-section h3 {
  font-size: 1em;
  line-height: 1.5em;
  color: #484848;
}

.badge {
  position: relative;
  margin: 0.2em 1em 0;
  width: 2.2em;
  height: 3em;
  border-radius: 10px;
}

.badge:before,
.badge:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.badge:before {
  transform: rotate(60deg);
}

.badge:after {
  transform: rotate(-60deg);
}

.badge .circle {
  width: 35px;
  height: 35px;
  position: absolute;
  background: #fff;
  z-index: 10;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.badge .circle svg {
  font-size: 1.5em;
  margin-top: 6px;
  margin-left: 0px;
}

.badge .font {
  display: inline-block;
  margin-top: 1em;
}

.ribbon {
  font-size: 12px;
}

.blue {
  background: linear-gradient(to bottom right, var(--color-primary-light) 0%, var(--color-primary-dark) 100%);
  color: var(--color-primary-light);
}

.section-gradient {
  /* See here: https://www.joshwcomeau.com/gradient-generator/ */
  background-image: linear-gradient(180deg,
      hsl(337deg 100% 72%) 0%,
      hsl(295deg 62% 71%) 29%,
      hsl(229deg 100% 78%) 43%,
      hsl(196deg 100% 50%) 57%,
      hsl(189deg 100% 47%) 71%,
      hsl(175deg 90% 43%) 100%);
  color: #ffffff;
}

.nav-links a,
.nav-links a:visited,
.nav-links a:active,
.nav-links a:hover {
  color: var(--color-secondary-main) !important;
  font-weight: 600;
  font-size: 0.9em;
  text-decoration: none;
}

.table-column-sticky {
  position: sticky;
  left: 0;
  background-color: #FAFAFA;
  z-index: 2;
}

/* Breakpoint for admin side, coincides with nav bar breakpoint */
@media (min-width: 900px) {
  .admin-navigation-menu span.MuiListItemText-primary {
    color: #fff;
  }
}

.course-checklist li {
  margin-bottom: 10px;
}

.header-logo {
  height: 20px;
  width: 170px;
}

.header-logo-icon {
  height: 20px;
  width: 29px;
}

.header-logo-container {
  margin-left: -29px;
}

.admin-wrapper h2,
.admin-wrapper h3 {
  font-weight: 400;
}

.admin-wrapper b,
.admin-wrapper strong {
  font-weight: 600;
}

/* Desktop styles. */
@media (min-width: 1000px) {
  .main-wrapper {
    margin: 65px auto 0;
  }

  .container {
    margin-left: 60px;
    margin-right: 60px;
  }

  .footer {
    padding: 100px;
  }

  .container.landing-page {
    margin-left: 40px;
    margin-right: 40px;
  }

  .layout-group:after {
    content: '';
    display: table;
    clear: both;
  }

  .hide-mobile {
    display: inherit;
  }

  .instructor-container {
    padding-bottom: 20px;
  }

  h2.avail-label {
    font-size: 36px !important;
    line-height: 36px !important;
  }

  .location-circle-image {
    width: 200px;
    height: 200px;
    background-size: cover;
    display: block;
    border-radius: 100px;
    text-align: center;
    line-height: 200px;
    margin: 0 auto;
  }

  .location-circle-image a {
    color: #ffffff !important;
    text-shadow: 2px 2px #000000;
  }

  .course-content {
    max-width: 600px;
    margin-left: 60px;
  }

  .page {
    padding-top: 20px;
    margin-bottom: 40px;
  }

  .booking-flow-container {
    max-width: 800px;
    margin: 0 auto;
  }

  .admin-navigation-menu .MuiListItem-root.Mui-selected,
  .admin-navigation-menu .MuiListItem-button:hover {
    background: #1e293a;
  }

  .admin-navigation-menu .css-tlelie-MuiListItemText-root {
    margin-top: 0;
    margin-bottom: 0;
  }

  /* For the admin navigation */
  .admin-nav-bar .PrivateHiddenCss-root {
    height: 100%;
  }

  .admin-nav-bar .MuiDrawer-docked {
    height: 100%;
  }

  .NavAppBar-drawer-paper {
    z-index: 1199;
  }

  .marketing-section {
    padding-left: 100px;
    padding-right: 100px;
  }

  .package-section-container {
    padding: 0;
  }

  .de-mega-hero h1 {
    font-size: 64px;
    line-height: 66px;
  }

  .landing-page.hero-section {
    padding: 0;
    margin: 0;
  }

  .marketing-section img {
    max-width: 100%;
    position: inherit;
    margin-left: 0;
    left: 0;
  }

  .landing-page.hero-section.partner .lead-block h1 {
    margin-bottom: inherit;
    font-size: 48px;
    line-height: 54px;
  }

  .landing-page.hero-section .lead-block h1 {
    margin-bottom: inherit;
    font-size: 64px;
    line-height: 64px;
  }

  .landing-page.hero-section h1 {
    font-size: 70px;
    line-height: 70px;
  }

  #landing-page-video-hero .video-content {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .header-logo {
    width: 290px;
    height: 34px;
  }

  .header-logo-icon {
    width: 50px;
    height: 34px;
  }

  .header-logo-container {
    margin-left: -50px;
  }

  .fc-toolbar {
    flex-direction: row;
  }

  .fc-toolbar-title {
    flex-direction: row;
    font-size: 1.75em !important;
  }
}